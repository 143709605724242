//Variables to replace
const poolId = 'eu-west-1_SN6zBnb0h'
const clientId = '7iefnmladveksqou2akgc0k057'
const api = 'https://xbd75eznl5.execute-api.eu-west-1.amazonaws.com/Prod'

const Config = {
    UserPoolId: poolId,
    AppClientId: clientId,
    ApiURL: api + '/notes'
}

export default Config